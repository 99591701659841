import React from 'react';

const content = 'Behavior Guidance Group’s Clinical Directors have both been teachers, direct therapists, and clinical supervisors. They have trained our professional staff to be sensitive to the unique needs of our clients. In addition, to ensure that Behavior Guidance Group always maintains the best possible level of service, all direct therapists are Registered Behavior Technicians. Supervisors are either Board Certified Behavior Analysts (BCBA) or BCBA exam ready with at least 2 years experience in designing and implementing behavior modification programs.';

const About = () =>
<div className="ml-3 mr-3">
  <div className="row d-flex justify-content-center">
    <div className="col-10">

      <div className="row d-none d-sm-block vh-100">
        <div className="m-5">
          <h2 className="h2-responsive mt-0 font-weight-bold">About Us</h2>
          <p className="lead">{content}</p>
        </div>
      </div>

      <div className="row d-block d-sm-none">
        <div className="m-5">
          <h2 className="h2-responsive mt-0 font-weight-bold">About Us</h2>
          <p className="lead">{content}</p>
        </div>
      </div>

    </div>
  </div>
</div>

export default About;
