import React from 'react';
import ReactDOM  from 'react-dom';
import { Provider } from 'react-redux';

import configureStore, { history } from './Redux';
import App from './App';

import registerServiceWorker from './registerServiceWorker';

const store = configureStore();

ReactDOM.render( (
  <Provider store={store}>
    <App history={history} />
  </Provider>
) , document.getElementById('root'));

registerServiceWorker();
