import {
  MDBAlert,
  MDBContainer
} from 'mdbreact';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

import EmailLink from '../Components/EmailLink';

const Message = props =>
<MDBContainer>
  {props.success ?
    <MDBAlert color="success" className="mt-3 mb-5">
      <h4 className="alert-heading">Well done!</h4>
      <p>{props.confirm}</p>
      <hr />
      <p className="mb-0 d-none d-sm-block">
        Whenever you need to, be sure to email us directly at <EmailLink /> or give us a call at <strong>(310) 853-8025</strong>.
      </p>
      <p className="mb-0 d-block d-sm-none">
        Whenever you need to, be sure to <Link to="/contact-us">Contact Us</Link> directly.
      </p>
    </MDBAlert>
    :
    <MDBAlert color="danger" className="mt-3 mb-5">
      <h4 className="alert-heading">Oops!</h4>
      <p>{props.message.length ? props.message : 'Something went wrong.'}</p>
      <hr />
      <p className="mb-0 d-none d-sm-block">
        Give it another shot, or you can always email us directly at <EmailLink /> or give us a call at <strong>(310) 853-8025</strong>.
      </p>
      <p className="mb-0 d-block d-sm-none">
        Give it another shot, or you can always <Link to="/contact-us">Contact Us</Link> directly.
      </p>
    </MDBAlert>
  }
</MDBContainer>

Message.propTypes = {
  success: PropTypes.bool.isRequired,
  confirm: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export default Message;
