import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdbreact';

class Navbar extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  }

  state = {
    isOpen: false,
  }

  isActiveMenuItem = (props, route) => {
    if (typeof props.location === 'undefined')
      return false;
    else
      return props.location.pathname === route;
  }

  toggleCollapse = e => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleMenuItemClick = e => {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <MDBNavbar color="special-color" dark expand="lg">
        <MDBNavbarBrand onClick={e => this.handleMenuItemClick(e)}>
           <MDBNavLink to="/">
            <strong className="white-text">Behavior Guidance Group</strong>
           </MDBNavLink>
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={e => this.toggleCollapse(e)} />
        <MDBCollapse id="behavior-guidance-group-nav" isOpen={this.state.isOpen} navbar>
          <MDBNavbarNav right>
            <MDBNavItem active={this.isActiveMenuItem(this.props, '/about-us')} onClick={e => this.handleMenuItemClick(e)}>
              <MDBNavLink to="/about-us">About Us</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={this.isActiveMenuItem(this.props, '/philosophy')} onClick={e => this.handleMenuItemClick(e)}>
              <MDBNavLink to="/philosophy">Philosophy</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={this.isActiveMenuItem(this.props, '/services')} onClick={e => this.handleMenuItemClick(e)}>
              <MDBNavLink to="/services">Services</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={this.isActiveMenuItem(this.props, '/contact-us')} onClick={e => this.handleMenuItemClick(e)}>
              <MDBNavLink to="/contact-us">Contact Us</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <span className="mr-2">Career Opportunities</span>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem active={this.isActiveMenuItem(this.props, '/career-opportunities/behavior-therapist')} onClick={e => this.handleMenuItemClick(e)}>
                    <MDBNavLink to="/career-opportunities/behavior-therapist" className="dropdown-nav-link">Behavior Therapist</MDBNavLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem active={this.isActiveMenuItem(this.props, '/career-opportunities/behavior-supervisor')} onClick={e => this.handleMenuItemClick(e)}>
                    <MDBNavLink to="/career-opportunities/behavior-supervisor" className="dropdown-nav-link">Behavior Supervisor</MDBNavLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      );
  }
}

export default Navbar;
