import api from '../Api';

import React from 'react';
import {Link} from 'react-router-dom';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter
} from 'mdbreact';

const Footer = () => (
  <MDBFooter color="special-color" className="font-small">
    <MDBContainer fluid className="text-center text-md-left mt-md-0" style={{ backgroundColor: "#2BBBAD" }}>
      <MDBRow>
        <MDBCol md="5" className="mt-md-0 mt-3 pl-5 pt-3">
          <p>
            We take great pride in providing the highest quality service and aim to always address any concerns that arise to accommodate your needs. However, in the unlikely circumstance we are unable to effectively deliver a solution, the following is a link to the <a className="highlighted-link" href="https://www.dds.ca.gov/Complaints/Complt_WB.cfm" target="_blank" rel="noopener noreferrer">DDS</a>.
          </p>
        </MDBCol>
        <MDBCol md="3" className="mb-md-0 d-mb-3-none pt-3 pb-3">
          <h5 className="text-uppercase">
            <Link to='/recent-posts'>Recent Posts</Link>
          </h5>

          <ul className="list-unstyled">
            {api.posts.map(post => (
              <li key={`recent-post-${post.id}`}>
                <Link to={`/recent-posts/${post.id}`}>{post.title}</Link>
              </li>
            ))}
          </ul>
        </MDBCol>
        <MDBCol md="2" className="mb-md-0 mb-3 pt-3 pb-3">
          <a href="https://imgbb.com/" target="new"><img src="https://i.ibb.co/QJLB1Hx/SC-Online-Badge.png" height="100" width="100" alt="SC-Online-Badge" border="0" /></a>
        </MDBCol>
        <MDBCol md="2" className="mb-md-0 mb-3 pt-3 pb-3 text-center">
          <a href="https://twitter.com/@BehaviorGG" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-2x fa-twitter"></i>
          </a>

          <a className="ml-3" href="https://www.facebook.com/pages/Behavior-Guidance-Group/236123233184200" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-2x fa-facebook-f"></i>
          </a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <div className="footer-copyright text-center py-3">
      <MDBContainer fluid>
        &copy; {new Date().getFullYear()} Copyright: <a href="https://mdbootstrap.com/education/bootstrap/">Behavior Guidance Group. All rights reserved.</a>
      </MDBContainer>
    </div>
  </MDBFooter>
)

export default Footer;
