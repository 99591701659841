import api from '../Api';

import React from 'react';

const SupervisorJobDescription = () =>
<div>
  <p>We are a small agency that is committed to providing effective, empirically validated educational and behavioral services to individuals with autism and other developmental disabilities.</p>
  <p>We are in search of part-time BCBA supervisors that have a least 2 years experience. Must be fluent in parent training, data collection, program supervision and development. Must have strong people managing skills.</p>

  <div>
    We are seeking BCBAs with:
    <div className="list-group-flush">
      <div className="list-group-item">
        {api.jobCriteria.supervisor.map( (criterion, index) =>
          <div className="list-group-item" key={`criterion-${index}`}>
             <div className="d-flex content-justify-start">
              <div><i className="fas fa-check mr-3 indigo p-2 white-text rounded" aria-hidden="true"></i></div>
              <div className="d-none d-sm-block">{criterion}</div>
              <small className="d-block d-sm-none">{criterion}</small>
             </div>
          </div>
        )}
      </div>
    </div>
  </div>

  <div>
    Employee Benefits:
    <div className="list-group-flush">
      <div className="list-group-item">
        {api.jobBenefits.supervisor.map( (benefit, index) =>
          <div className="list-group-item" key={`benefit-${index}`}>
            <div className="d-flex content-justify-start">
              <div><i className="fas fa-check mr-3 default-color p-2 white-text rounded" aria-hidden="true"></i></div>
              <div className="d-none d-sm-block">{benefit}</div>
              <small className="d-block d-sm-none">{benefit}</small>
             </div>
          </div>
        )}
      </div>
    </div>
  </div>

</div>

export default SupervisorJobDescription;
