import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import contact from './Contact';
import job from './Job';
import posts from './Posts';
import recaptcha from './Modules/Recaptcha';
import flow from './Modules/Flow';

const rootReducer = history => combineReducers({
	contact,
	flow,
	job,
	posts,
	recaptcha,
	router: connectRouter(history),
});

export default rootReducer;
