import React from 'react';
import { Route, Switch } from 'react-router';

import About from './About';
import BehaviorSupervisor from './BehaviorSupervisor';
import BehaviorTherapist from './BehaviorTherapist';
import Contact from './Contact';
import Footer from '../Layout/Footer.js';
import Home from './Home';

import Navbar from '../Layout/Navbar.js';

import Philosophy from './Philosophy';
import Posts from './Posts';
import Services from './Services';

/*
  TODO :: implement NoMatch route
*/

const routes = (
  <div>
    <Route path="/" component={Navbar} />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about-us" component={About} />
      <Route path="/philosophy" component={Philosophy} />
      <Route path="/services" component={Services} />
      <Route path="/contact-us" component={Contact} />
      <Route path="/career-opportunities/behavior-therapist" exact component={BehaviorTherapist} />
      <Route path="/career-opportunities/behavior-supervisor" exact component={BehaviorSupervisor} />
      <Route path="/recent-posts/:id?" component={Posts} />
    </Switch>
    <Route path="/" component={Footer} />
  </div>
);

export default routes;
