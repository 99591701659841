// ACTIONS
export const SET_POST = 'posts/SET_POST';

// INITIAL STATE
const initPosts = () => ({
  postId: null,
});

// REDUCERS
const posts = (state = initPosts(), action) => {
  switch (action.type) {
    case SET_POST: {
      const { postId } = action;
      return {
        postId,
      };
    }
    default:
      return state;
  }
};

export default posts;

// ACTION CREATORS
export const setPost = id => ({
  type: SET_POST,
  postId: id,
});

// THUNKS
export const viewPost = (dispatch, id) => dispatch( setPost(id) );
