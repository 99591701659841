import _ from 'lodash';

import { post, CancelToken, isCancel } from 'axios';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow
} from 'mdbreact';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import isEmail from 'validator/lib/isEmail';

import {
  initContact,
  setNameFocus,
  setField,
  setError,
  initFlow,
  setMode,
  initGoogleRecaptcha,
  failGoogleRecaptcha,
  changeGoogleRecaptchaValue,
  employGoogleRecaptcha,
  unemployGoogleRecaptcha
} from '../Redux/Reducers/Contact';

import Google from '../Model/Google';

import EmailLink from '../Components/EmailLink';
import Message from '../Components/Message';

const { siteKey } = Google.recaptcha;
const { grecaptchaObject, dataLayer } = window;

class Contact extends Component {
  constructor(props) {
    super(props);

    const { bypassGoogleRecaptcha } = props;
    if (bypassGoogleRecaptcha)
      props.unemployGoogleRecaptcha();
    else
      props.employGoogleRecaptcha();
  }

  // reset google recaptcha used in other places before mounting
  componentWillMount() {
    this.props.initContact();
    this.props.initFlow();

    if (!this.props.bypassGoogleRecaptcha)
      this.props.initGoogleRecaptcha();
  }

  componentDidMount() {
    if(this.nameRef) this.nameRef.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    const { focusOnName } = this.props;

    if (focusOnName) {
      this.nameRef.focus();
      this.props.setNameFocus(false);
    }
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
    clearTimeout(this.timer);
  }

  makeFieldValid = field => {
    this[`${field}Ref`].parentElement.classList.remove('invalid');
  }

  makeFieldInvalid = field => {
    this[`${field}Ref`].parentElement.classList.remove('invalid');
    this[`${field}Ref`].parentElement.classList.add('invalid');
  }

  canWeSubmit = () => {
    const { errors, bypassGoogleRecaptcha, googleRecaptchaValue } = this.props;
    let areThereErrors = false;

    _.forIn(errors, (value, key) => {
      if (value.length > 0) {
        areThereErrors = true;
      }
    });

    if (!bypassGoogleRecaptcha) {
      if (_.isNull(googleRecaptchaValue)) {
        this.props.failGoogleRecaptcha();
        areThereErrors = true;
      }
    }

    return !areThereErrors;
  }

  setFieldValidity = (field, value) => {
    if (value) {
      this.makeFieldValid(field);
    } else {
      this.makeFieldInvalid(field);
    }
  }

  setError = (field, message) => {
    this.props.setError(field, message);
  }

  handleGoogleRecaptchaChange = value => {
    this.props.changeGoogleRecaptchaValue(value);
  }

  doChangeHandler = (name, value) => {
    switch(name) {

      case 'name':
      case 'subject':
      case 'message':
        if (!value.length) {
          this.setFieldValidity(name, false);
          this.setError(name, _.capitalize(name) + ' can not be empty');
        }
        else {
          this.setFieldValidity(name, true);
          this.setError(name, '');
        }
      break;

      case 'email':
        if (!value.length) {
          this.setFieldValidity(name, false);
          this.setError(name, 'Email can not be empty');
        }
        else if (!isEmail(value)) {
          this.setFieldValidity(name, false);
          this.setError(name, 'Enter a valid email');
        }
        else {
          this.setFieldValidity(name, true);
          this.setError(name, '');
        }
      break;

      default:
        // ...
    }

    this.props.setField(name, value);
  }

  changeHandler = event => {
    const { name, value} = event.target;
    this.doChangeHandler(name, value);
  }

  submitHandler = async (event) => {
    event.preventDefault();

    if (this.canWeSubmit()) {
      this.props.setMode('submitting');

      // actual submit happens here
      const doWeSubmit = _.endsWith(window.location.hostname, 'behaviorguidance.com');
      if (doWeSubmit) {
        const { name, email, subject, message, googleRecaptchaValue } = this.props;
        const params = new FormData();
        params.append('name', name);
        params.append('email', email);
        params.append('subject', subject);
        params.append('message', message);
        params.append('g-recaptcha-response', googleRecaptchaValue);

        this.cancelTokenSource = CancelToken.source();
        params.append('cancelToken', this.cancelTokenSource.token);

        try {
          const response = await post('/email/contact.php', params);

          const { success, message } = response.data;
          this.props.setMode(
            'submitted',
            success,
            message
          );
        } catch (err) {
          if (isCancel(err)) {
            // ignore
          }
          else {
            // propagate
            this.props.setMode(
              'submitted',
              false,
              'Something went wrong.'
            );
          }
        } finally {
          this.cancelTokenSource = null;
        }
      }
      else {
        this.timer = setTimeout(() => {
          this.props.setMode(
            'submitted',
            true,
            'Email sent successfully.'
          );
        }, 1000);

        /* GTM :: track Contact Form being submitted */
        if (dataLayer) {
          dataLayer.push({
            event: 'GA Event - Contact Form Submitted',
            category: 'Contact',
            action: 'Contact Form Submitted',
            value: this.props.email,
          });
        }
      }
    }
    else {
      const { errors } = this.props;
      _.forIn(errors, (value, key) => {
        this.doChangeHandler(key, this.props[key]);
      });
    }
  }

  submitAnother = event => {
    event.preventDefault();
    this.props.setMode('showForm');
  }

  render() {
    const {
      name,
      email,
      subject,
      message,
      errors,
      mode,
      isGoogleRecaptchaVerified,
      disabled,
      submittedSuccess,
      submittedMessage
    } = this.props;

    return (
      <div className="ml-3 mr-3">

        <div className="row d-flex justify-content-center mb-5">
          <div className="col-10">

            <div className="row">
              <div className="col-10 mt-5">
                <h2 className="h2-responsive mt-0 font-weight-bold">Contact Us</h2>
                <p className="w-responsive">All your comments or questions are welcome.</p>
              </div>
            </div>

            <MDBRow>
              <MDBCol lg="5" className="lg-0 mb-4">
                { (
                    mode === 'showForm'
                    || mode === 'submitting'
                  ) ? (
                  <MDBCard>
                    <MDBCardBody>
                      <form
                        name="contact-form"
                        onSubmit={this.submitHandler}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="grey-text">

                          <MDBInput
                            id="name"
                            name="name"
                            value={name}
                            onChange={e => this.changeHandler(e)}
                            label={errors.name && errors.name.length ? errors.name : 'Your name'}
                            icon="user"
                            group
                            type="text"
                            disabled={disabled}
                            containerClass="contact-us-container"
                            className="contact-us-input"
                            labelClass="contact-us-label"
                            inputRef={ref => this.nameRef = ref}
                          />

                          <MDBInput
                            id="email"
                            name="email"
                            label={errors.email && errors.email.length ? errors.email : 'Your email'}
                            value={email}
                            onChange={e => this.changeHandler(e)}
                            icon="envelope"
                            group
                            type="email"
                            disabled={disabled}
                            containerClass="contact-us-container"
                            className="contact-us-input"
                            labelClass="contact-us-label"
                            inputRef={ref => this.emailRef = ref}
                          />

                          <MDBInput
                            id="subject"
                            name="subject"
                            label={errors.subject && errors.subject.length ? errors.subject : 'Your subject'}
                            value={subject}
                            onChange={e => this.changeHandler(e)}
                            icon="tag"
                            group
                            type="text"
                            disabled={disabled}
                            containerClass="contact-us-container"
                            className="contact-us-input"
                            labelClass="contact-us-label"
                            inputRef={ref => this.subjectRef = ref}
                          />
                          <MDBInput
                            id="message"
                            name="message"
                            label={errors.message && errors.message.length ? errors.message : 'Your message'}
                            value={message}
                            onChange={e => this.changeHandler(e)}
                            type="textarea"
                            rows="3"
                            icon="pencil-alt"
                            disabled={disabled}
                            containerClass="contact-us-container"
                            className="contact-us-input"
                            labelClass="contact-us-label"
                            inputRef={ref => this.messageRef = ref}
                          />

                          <div className="d-flex justify-content-center mt-3 mb-3">
                            <div className="d-none d-sm-block">
                              <ReCAPTCHA
                                sitekey={siteKey}
                                grecaptcha={grecaptchaObject}
                                size="normal"
                                onChange={this.handleGoogleRecaptchaChange}
                              />
                            </div>
                            <div className="d-block d-sm-none">
                              <ReCAPTCHA
                                sitekey={siteKey}
                                grecaptcha={grecaptchaObject}
                                size="compact"
                                onChange={this.handleGoogleRecaptchaChange}
                              />
                            </div>
                          </div>
                          {!isGoogleRecaptchaVerified ?
                            <div className="text-center">
                              <div className="contact-us-container invalid">
                                <small id="recaptcha" className="contact-us-label">Please verify that you are a human</small>
                              </div>
                            </div>
                            : ''}

                        </div>
                        <div className="text-center">
                          {mode === 'showForm' ? (
                            <MDBBtn color="default" type="submit">
                              Send <MDBIcon far icon="paper-plane" className="ml-2" />
                            </MDBBtn>
                          ) : (
                            <i className="fas fa-5x fa-spinner fa-spin mt-3" style={{ color: '#2bbbad' }}></i>
                          )}
                        </div>
                      </form>
                    </MDBCardBody>
                  </MDBCard>
                ) : ''}

                {mode === 'submitted' ?
                  <div>
                    <Message
                      success={submittedSuccess}
                      message={submittedMessage}
                      confirm="You successfully sent us a message."
                    />
                    <div className="text-center">
                      <MDBBtn className="send-another" color="default" onClick={e => this.submitAnother(e)}>
                        Send Another <MDBIcon far icon="paper-plane" className="ml-2" />
                      </MDBBtn>
                    </div>
                  </div>
                : ''}
              </MDBCol>
              <MDBCol lg="7">
                <div
                  id="map-container"
                  className="rounded z-depth-1-half map-container"
                  style={{ height: "400px" }}
                >
                  <iframe
                    src="https://maps.google.com/maps?q=Behavior+Guidance+Group,13101+W+Washington+Blvd+Los Angeles+CA+90066&z=13&output=embed"
                    allowFullScreen
                    title="Behavior Guidance Group Office"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                  />
                </div>
                <br />
                <MDBRow className="text-center">
                  <MDBCol md="4">
                    <MDBBtn color="indigo" className="rounded-pill address-icon">
                      <MDBIcon icon="map-marker-alt" />
                    </MDBBtn>
                    <p className="small">
                      13101 W Washington Blvd, Suite 238<br />
                      Los Angeles CA 90066
                    </p>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBBtn color="indigo" className="rounded-pill address-icon">
                      <MDBIcon icon="phone" />
                    </MDBBtn>
                    <p className="small">
                      (310) 853-8025
                    </p>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBBtn color="indigo" className="rounded-pill address-icon">
                      <MDBIcon icon="envelope" />
                    </MDBBtn>
                    <p className="small">
                      <EmailLink />
                    </p>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>

          </div>
        </div>

      </div>
    );
  }
}

Contact.propTypes = {
  initContact: PropTypes.func.isRequired,
  bypassGoogleRecaptcha: PropTypes.bool,
  setField: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setNameFocus: PropTypes.func.isRequired,
  initFlow: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  initGoogleRecaptcha: PropTypes.func.isRequired,
  failGoogleRecaptcha: PropTypes.func.isRequired,
  changeGoogleRecaptchaValue: PropTypes.func.isRequired,
  unemployGoogleRecaptcha: PropTypes.func.isRequired,
  employGoogleRecaptcha: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  name: state.contact.name,
  email: state.contact.email,
  subject: state.contact.subject,
  message: state.contact.message,
  focusOnName: state.contact.focusOnName,
  errors: state.contact.errors,
  disabled: state.flow.disabled,
  mode: state.flow.mode,
  submittedSuccess: state.flow.submittedSuccess,
  submittedMessage: state.flow.submittedMessage,
  bypassGoogleRecaptcha: state.recaptcha.bypassGoogleRecaptcha,
  googleRecaptchaValue: state.recaptcha.googleRecaptchaValue,
  isGoogleRecaptchaVerified: state.recaptcha.isGoogleRecaptchaVerified,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
      initContact,
      setField,
      setError,
      setNameFocus,
      initFlow,
      setMode,
      initGoogleRecaptcha,
      failGoogleRecaptcha,
      changeGoogleRecaptchaValue,
      unemployGoogleRecaptcha,
      employGoogleRecaptcha,
    },
    dispatch
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact));
