// ACTIONS
export const INIT_JOB = 'job/INIT_JOB';
export const SET_JOB = 'job/SET_JOB';
export const SET_FILE_UPLOAD_DATA = 'job/SET_FILE_UPLOAD_DATA';
export const SET_FIELD = 'job/SET_FIELD';
export const SET_ERROR = 'job/SET_ERROR';

export const INIT_FLOW = 'job/INIT_FLOW';
export const SET_MODE = 'job/SET_MODE';

export const INIT_GOOGLE_RECAPTCHA = 'job/INIT_GOOGLE_RECAPTCHA';
export const FAIL_GOOGLE_RECAPTCHA = 'job/FAIL_GOOGLE_RECAPTCHA';
export const CHANGE_GOOGLE_RECAPTCHA_VALUE = 'job/CHANGE_GOOGLE_RECAPTCHA_VALUE';
export const EMPLOY_GOOGLE_RECAPTCHA = 'job/EMPLOY_GOOGLE_RECAPTCHA';
export const UNEMPLOY_GOOGLE_RECAPTCHA = 'job/UNEMPLOY_GOOGLE_RECAPTCHA';

// INITIAL STATE
const initState = () => ({
  firstName: '',
  lastName: '',
  email: '',
  file: '',
  fileUploadData: null,
  errors: {
    firstName: 'Your first name',
    lastName: 'Your last name',
    email: 'Your email',
    file: 'Your resume',
  },
  job: '',
});

// REDUCERS
const job = (state = initState(), action) => {
  switch (action.type) {
    case INIT_JOB: {
      return initState();
    }
    case SET_JOB: {
      const { job } = action;

      return {
        ...state,
        job,
      };
    }
    case SET_FILE_UPLOAD_DATA: {
      const { file, fileUploadData } = action;

      return {
        ...state,
        file,
        fileUploadData,
      };
    }
    case SET_FIELD: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case SET_ERROR: {
      const errors = {
        ...state.errors,
        [action.field]: action.message,
      };

      return {
        ...state,
        errors,
      };
    }
    default:
      return state;
  }
};

export default job;

// ACTION CREATORS (native)
export const initJob = () => ({
  type: INIT_JOB,
});

export const setJob = job => ({
  type: SET_JOB,
  job,
});

export const setFileUploadData = (file, fileUploadData) => ({
  type: SET_FILE_UPLOAD_DATA,
  file,
  fileUploadData,
});

export const setField = (name, value) => ({
  type: SET_FIELD,
  name,
  value,
});

export const setError = (field, message) => ({
  type: SET_ERROR,
  field,
  message,
});

// ACTION CREATORS (flow)
export const initFlow = () => ({
  type: INIT_FLOW,
});

export const setMode = (mode, success, message) => ({
  type: SET_MODE,
  mode,
  success,
  message,
});

// ACTION CREATORS (recaptcha)
export const initGoogleRecaptcha = () => ({
  type: INIT_GOOGLE_RECAPTCHA,
});

export const failGoogleRecaptcha = () => ({
  type: FAIL_GOOGLE_RECAPTCHA,
});

export const changeGoogleRecaptchaValue = value => ({
  type: CHANGE_GOOGLE_RECAPTCHA_VALUE,
  value,
});

export const employGoogleRecaptcha = () => ({
  type: EMPLOY_GOOGLE_RECAPTCHA,
});

export const unemployGoogleRecaptcha = () => ({
  type: UNEMPLOY_GOOGLE_RECAPTCHA,
});

// THUNKS
