const api = {
  posts: [
    {
      id: 2,
      title: 'Behavior Guidance Redesign',
      avatar: 'tech-support.png',
      alt: 'Behavior Guidance Tech Support',
      content: 'Our website has been redesigned! We used a contemporary front end technology and design to help you enjoy it better.',
      author: 'tech support',
      date: 'March 1st 2019',
    },
    {
      id: 1,
      title: 'Behavior Guidance Group Launch',
      avatar: 'admin.png',
      alt: 'Behavior Guidance Group Admin',
      content: 'Our website has been launched! You can find out about us and our principles of conducting of our work. Additionally you can apply for a position of behavior therapist or behavior supervisor or justl eave us a note. We are here and ready to help guide you to your best self!',
      author: 'admin',
      date: 'October 14th 2014',
    },
  ],
  jobCriteria: {
    therapist: [
      'A Bachelor\'s or Master\'s in psychology, education or related field',
      'At least 2 years of direct service experience using ABA methodologies',
      'A working cell phone with an ongoing service plan',
      'Access to computer with internet connection',
      'Valid driver\'s license with insurance',
      'A clear FBI & DOJ record',
      'An enthusiastic and positive attitude with a strong desire to help others',
    ],
    supervisor: [
      'At least 2 years of supervision experience',
      'At least 2 years supervision experience working in schools collaborating with teachers, attending IEPs and creating BSPs',
      'At least 2 years experience in conducting and writing FBAs',
      'At least 2 years writing and running programs with data',
      'A working cell phone with an ongoing service plan',
      'Access to computer with internet connection',
      'Valid driver\'s license with insurance',
      'A clear FBI & DOJ record',
      'An enthusiastic and positive attitude with a strong desire to help others',
    ],
  },
  jobBenefits: {
    therapist: [
      'Initial and ongoing paid training',
      'Ongoing supervision',
      'Opportunities for growth within the company',
      'Paid drive time and mileage between clients',
      'Opportunities for BCBA or BCaBA supervision hours',
    ],
    supervisor: [
      'Ongoing support and supervision by clinical directors',
      'Opportunities for growth within the company',
      'Paid drive time and mileage between clients',
    ],
  },
  services: {
    vendors: [
      'Westside Regional Center',
      'Frank D. Lanterman Regional Center',
      'Los Angeles Unified School District',
      'Culver City Unified School District',
    ],
    credentials: [
      'Aetna',
      'Anthem Blue Cross',
      'Care 1st',
      'LA Care',
      'Kaiser',
    ],
    reducing: [
      'Self-injury',
      'Aggression',
      'Tantrums',
      'Stereotypy',
      'Rigidity',
      'Elopement',
      'Property Destruction',
    ],
    increasing: [
      'Functional Communication',
      'Safety Skills',
      'Social Skills',
      'Academics',
      'Daily Living Skills',
    ],
  },
  testData: {
    users: {
      contact: {
        name: 'Tester McTesterson',
        email: 'tester@me.com',
        subject: 'Test Subject',
        message: 'This is a test message.',
      },
      supervisor: {
        firstName: 'Super',
        lastName: 'Visor',
        email: 'super@visor.net',
        file: 'test-resume.pdf',
      },
      therapist: {
        firstName: 'Therra',
        lastName: 'Pissed',
        email: 'thera@pissed.org',
        file: 'test-resume.pdf',
      },
    },
  },
};

export default api;
