// ACTIONS
export const INIT_CONTACT = 'contact/INIT_CONTACT';
export const SET_NAME_FOCUS = 'contact/SET_NAME_FOCUS';
export const SET_FIELD = 'contact/SET_FIELD';
export const SET_ERROR = 'contact/SET_ERROR';

export const INIT_FLOW = 'contact/INIT_FLOW';
export const SET_MODE = 'contact/SET_MODE';

export const INIT_GOOGLE_RECAPTCHA = 'contact/INIT_GOOGLE_RECAPTCHA';
export const FAIL_GOOGLE_RECAPTCHA = 'contact/FAIL_GOOGLE_RECAPTCHA';
export const CHANGE_GOOGLE_RECAPTCHA_VALUE = 'contact/CHANGE_GOOGLE_RECAPTCHA_VALUE';
export const EMPLOY_GOOGLE_RECAPTCHA = 'contact/EMPLOY_GOOGLE_RECAPTCHA';
export const UNEMPLOY_GOOGLE_RECAPTCHA = 'contact/UNEMPLOY_GOOGLE_RECAPTCHA';

// INITIAL STATE
const initState = () => ({
  name: '',
  email: '',
  subject: '',
  message: '',
  errors: {
    name: 'Your name',
    email: 'Your email',
    subject: 'Your subject',
    message: 'Your message',
  },
  focusOnName: true,
});

// REDUCERS
const contact = (state = initState(), action) => {
  switch (action.type) {
    case INIT_CONTACT: {
      return initState();
    }
    case SET_NAME_FOCUS: {
      return {
        ...state,
        focusOnName: action.focus,
      };
    }
    case SET_FIELD: {
      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case SET_ERROR: {
      const errors = {
        ...state.errors,
        [action.field]: action.message,
      };

      return {
        ...state,
        errors,
      };
    }
    default:
      return state;
  }
};

export default contact;

// ACTION CREATORS (native)
export const initContact = () => ({
  type: INIT_CONTACT,
});

export const setNameFocus = focus => ({
  type: SET_NAME_FOCUS,
  focus,
});

export const setField = (name, value) => ({
  type: SET_FIELD,
  name,
  value,
});

export const setError = (field, message) => ({
  type: SET_ERROR,
  field,
  message,
});

// ACTION CREATORS (flow)
export const initFlow = () => ({
  type: INIT_FLOW,
});

export const setMode = (mode, success, message) => ({
  type: SET_MODE,
  mode,
  success,
  message,
});

// ACTION CREATORS (recaptcha)
export const initGoogleRecaptcha = () => ({
  type: INIT_GOOGLE_RECAPTCHA,
});

export const failGoogleRecaptcha = () => ({
  type: FAIL_GOOGLE_RECAPTCHA,
});

export const changeGoogleRecaptchaValue = value => ({
  type: CHANGE_GOOGLE_RECAPTCHA_VALUE,
  value,
});

export const employGoogleRecaptcha = () => ({
  type: EMPLOY_GOOGLE_RECAPTCHA,
});

export const unemployGoogleRecaptcha = () => ({
  type: UNEMPLOY_GOOGLE_RECAPTCHA,
});

// THUNKS
