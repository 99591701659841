import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBRow
} from 'mdbreact';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import api from '../Api';

const Posts = props => {
  const postId = props.match.params.id;

  return (
    <div className="ml-3 mr-3">
      <div className="row d-flex justify-content-center">
        <div className="col-10">

          <div className="row">
            <div className="col-10 mt-5 ml-5">
              <h2 className="h2-responsive mt-0 mb-4 font-weight-bold">Recent Posts</h2>
            </div>
          </div>

           <MDBRow>
            <MDBCol md="12">
              {postId ? (
                <div className="float-right mb-3">
                  <Link to="/recent-posts">
                    <MDBBtn gradient="purple" className="btn-sm waves-effect btn-apply">
                      All Recent <MDBIcon far icon="file" className="ml-1" />
                    </MDBBtn>
                  </Link>
                </div>
              ) : ''}
            </MDBCol>
          </MDBRow>

          <MDBRow className={postId ? 'mt-3 ml-1 mr-1 vh-100' : 'ml-1 mr-1'}>
            <MDBCol md="12">

              {api.posts.map(post =>
                (
                  !postId ||
                  postId.toString() === post.id.toString()
                ) ?
                <MDBCard className="mb-5 post" style={{ width: "100%" }} key={`post-${post.id}`}>
                  <MDBCardBody>
                    <MDBCardTitle>
                      <Link className="view-post-link" to={`/recent-posts/${post.id}`}>
                        {post.title}
                      </Link>
                    </MDBCardTitle>
                    <div className="d-flex flex-row mt-3">
                      <MDBCardImage
                        className="img-fluid"
                        src={`/img/avatars/${post.avatar}`}
                        alt={post.alt}
                        style={{cursor: 'pointer'}}
                      />
                      <MDBCardText className="ml-5">
                          {post.content}
                      </MDBCardText>
                    </div>
                    <hr/>
                    <small className="d-flex justify-content-between">
                      <div className="p-2 col-example text-left">
                        <i className="far fa-clock pr-1"></i> {post.date} by {post.author}
                      </div>
                      <div className="p-2 col-example text-left">
                        <MDBBtn social="fb" size="sm" className="btn-facebook" href="https://www.facebook.com/pages/Behavior-Guidance-Group/236123233184200" target="_blank" rel="noopener noreferrer">
                          <MDBIcon fab icon="facebook-f" className="pr-1" /> Facebook
                        </MDBBtn>
                        <MDBBtn social="tw" size="sm" className="btn-twitter" href="https://twitter.com/@BehaviorGG" target="_blank" rel="noopener noreferrer">
                          <MDBIcon fab icon="twitter" className="pr-1" /> Twitter
                        </MDBBtn>
                      </div>
                    </small>
                  </MDBCardBody>
                </MDBCard>
                : ''
              )}

            </MDBCol>
          </MDBRow>

        </div>
      </div>
    </div>
  );
}

Posts.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Posts;
