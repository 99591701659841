import _ from 'lodash';

import { post, CancelToken, isCancel } from 'axios';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRow
} from 'mdbreact';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import isEmail from 'validator/lib/isEmail';

import {
  initJob,
  setJob,
  setFileUploadData,
  setField,
  setError,
  initFlow,
  setMode,
  initGoogleRecaptcha,
  failGoogleRecaptcha,
  changeGoogleRecaptchaValue,
  employGoogleRecaptcha,
  unemployGoogleRecaptcha
} from '../Redux/Reducers/Job';

import Google from '../Model/Google';

import Message from '../Components/Message';
import TherapistJobDescription from '../Components/TherapistJobDescription';
import SupervisorJobDescription from '../Components/SupervisorJobDescription';

const { siteKey } = Google.recaptcha;
const grecaptchaObject = window.grecaptcha;

class Job extends Component {
  constructor(props) {
    super(props);

    const { title, bypassGoogleRecaptcha } = props;

    props.setJob(title);

    if (bypassGoogleRecaptcha) {
      props.unemployGoogleRecaptcha();
    }
    else {
      props.employGoogleRecaptcha();
    }
  }

  // reset google recaptcha used in other places before mounting
  componentWillMount() {
    this.props.initJob();
    this.props.initFlow();

    if (!this.props.bypassGoogleRecaptcha)
      this.props.initGoogleRecaptcha();
  }

  componentWillUnmount() {
    this.cancelTokenSource && this.cancelTokenSource.cancel();
    clearTimeout(this.timer);
  }

  scrollToResumeForm = event => {
    this.firstNameRef.focus();
  }

  clearCaptcha = () => {
    this.props.initGoogleRecaptcha();
  }

  canWeSubmit = () => {
    const { errors, fileUploadData, bypassGoogleRecaptcha, googleRecaptchaValue } = this.props;
    let areThereErrors = false;

    _.forIn(errors, (value, key) => {
      if(key !== 'file') {
        if (value.length > 0) {
          areThereErrors = true;
        }
      } else {
        if(_.isNull(fileUploadData)) {
          areThereErrors = true;
        }
      }
    });

    if (!bypassGoogleRecaptcha) {
      if (_.isNull(googleRecaptchaValue)) {
        this.props.failGoogleRecaptcha();
        areThereErrors = true;
      }
    }

    return !areThereErrors;
  }

  getFieldReference = field => (
    field === 'file'
      ? this[`${field}Ref`].parentElement.parentElement.parentElement
      : this[`${field}Ref`].parentElement
  )

  makeFieldValid = field => {
    const el = this.getFieldReference(field);
    el.classList.remove('invalid');
  }

  makeFieldInvalid = field => {
    const el = this.getFieldReference(field);
    el.classList.remove('invalid');
    el.classList.add('invalid');
  }

  setFieldValidity = (field, value) => {
    if (value) {
      this.makeFieldValid(field);
    } else {
      this.makeFieldInvalid(field);
    }
  }

  setError = (field, message) => {
    this.props.setError(field, message);
  }

  handleGoogleRecaptchaChange = value => {
    this.props.changeGoogleRecaptchaValue(value);
  }

  doChangeHandler = (name, value) => {
    switch(name) {

      case 'firstName':
      case 'lastName':

        let fieldName = name.replace( /([A-Z])/g, " $1" );
        if (!value.length) {
          this.setFieldValidity(name, false);
          this.setError(name, _.capitalize(fieldName) + ' can not be empty');
        }
        else {
          this.setFieldValidity(name, true);
          this.setError(name, '');
        }
      break;

      case 'email':
        if (!value.length) {
          this.setFieldValidity(name, false);
          this.setError(name, 'Email can not be empty');
        }
        else if (!isEmail(value)) {
          this.setFieldValidity(name, false);
          this.setError(name, 'Enter a valid email');
        }
        else {
          this.setFieldValidity(name, true);
          this.setError(name, '');
        }
      break;

      case 'file':
        if (!value.length) {
          this.setFieldValidity(name, false);
          this.setError(name, 'Provide your resume');
        }
        else {
          this.setFieldValidity(name, true);
          this.setError(name, '');
        }
      break;

      default:
        // ...
    }

    this.props.setField(name, value);
  }

  changeHandler = event => {
    const { name, value} = event.target;
    this.doChangeHandler(name, value);

    if (name === 'file') {
      const { files } = event.target;
      const file = files.length ? files[0].name : '';
      const fileUploadData = files.length ? files[0] : null;

      this.props.setFileUploadData(file, fileUploadData);

      if (!files.length) {
        this.setFieldValidity('file', false);
        this.setError('file', 'Provide your resume');
      }
    }
  }

  submitHandler = event => {
    event.preventDefault();

    if (this.canWeSubmit()) {
      this.props.setMode('submitting');

      // actual submit happens here
      const doWeSubmit = _.endsWith(window.location.hostname, 'behaviorguidance.com');
      if (doWeSubmit) {
        const {
          firstName,
          lastName,
          email,
          job,
          googleRecaptchaValue,
          fileUploadData
        } = this.props;

        const params = new FormData();
        params.append('firstName', firstName);
        params.append('lastName', lastName);
        params.append('email', email);
        params.append('job', job);
        params.append('file', fileUploadData);
        params.append('g-recaptcha-response', googleRecaptchaValue);

        this.cancelTokenSource = CancelToken.source();
        params.append('cancelToken', this.cancelTokenSource.token);

        try {
          post('/email/apply.php', params);
          // const { success, message } = response.data;
          // this.props.setMode(
          //   'submitted',
          //   success,
          //   message
          // );
          this.props.setMode(
            'submitted',
            true,
            'Job application sent successfully!'
          );
        } catch (err) {
          if (isCancel(err)) {
            // ignore
          }
          else {
            // propagate
            this.props.setMode(
              'submitted',
              false,
              'Something went wrong.'
            );
          }
        } finally {
          this.cancelTokenSource = null;
        }
      }
      else {
        this.timer = setTimeout(() => {
          this.props.setMode(
            'submitted',
            true,
            'Job application sent successfully!'
          );
        }, 1000);
      }
    }
    else {
      const { errors } = this.props;
      _.forIn(errors, (value, key) => {
        this.doChangeHandler(key, this.props[key]);
      });
    }
  }

  render() {
    const {
      job,
      mode,
      firstName,
      lastName,
      email,
      disabled,
      errors,
      file,
      isGoogleRecaptchaVerified,
      submittedSuccess,
      submittedMessage
    } = this.props;

    return (
      <div className="ml-3 mr-3">
        <div className="row d-flex justify-content-center">
          <div className="col-10">

            <div className="row">
              <div className="col-10 m-5">

                <h2 className="h2-responsive mt-0 font-weight-bold">
                  {job === 'supervisor' ?
                    'Behavior Supervisor' : 'Behavior Therapist'
                  }
                </h2>
                {mode !== 'submitted' ? (
                  <div className="float-right">
                    <MDBBtn gradient="purple" className="btn-sm waves-effect btn-apply" onClick={e => this.scrollToResumeForm(e)}>
                      Apply <MDBIcon far icon="file" className="ml-1" />
                    </MDBBtn>
                  </div>
                ) : ''}

                <h3 className="h3-responsive mt-5">
                  Career Opportunity of {job === 'supervisor' ?
                    'Behavior Supervisor' : 'Behavior Therapist'
                  }
                </h3>

                {job === 'supervisor' ?
                  <SupervisorJobDescription /> : <TherapistJobDescription />
                }

              </div>
            </div>

            {/* apply for a job form */}
            <div className="row d-flex justify-content-center">
              <div className="col-xs-12">

                <h3 className="h3-responsive">Apply For This Position</h3>
                <p>Fill out this form and attach your resume. Please provide 3 references with resume.</p>

                <MDBRow>
                  <MDBCol className="lg-0">
                    {(
                      mode === 'showForm'
                      || mode === 'submitting'
                    ) ? (
                      <MDBCard className="mt-3 mb-5">
                        <MDBCardBody>
                          <form
                            name={`behavior-${job}-resume-form`}
                            onSubmit={this.submitHandler}
                            noValidate
                            autoComplete="off"
                            encType="multipart/form-data"
                          >
                            <div className="grey-text">
                              <MDBInput
                                id="job"
                                name="job"
                                value={job}
                                type="hidden"
                              />

                              <MDBInput
                                id="firstName"
                                name="firstName"
                                value={firstName}
                                onChange={e => this.changeHandler(e)}
                                label={errors.firstName && errors.firstName.length ? errors.firstName : 'Your first name'}
                                icon="user"
                                group
                                type="text"
                                disabled={disabled}
                                containerClass="contact-us-container"
                                className="contact-us-input"
                                labelClass="contact-us-label"
                                inputRef={ref => this.firstNameRef = ref}
                              />

                              <MDBInput
                                id="lastName"
                                name="lastName"
                                value={lastName}
                                onChange={e => this.changeHandler(e)}
                                label={errors.lastName && errors.lastName.length ? errors.lastName : 'Your last name'}
                                icon="user"
                                group
                                type="text"
                                disabled={disabled}
                                containerClass="contact-us-container"
                                className="contact-us-input"
                                labelClass="contact-us-label"
                                inputRef={ref => this.lastNameRef = ref}
                              />

                              <MDBInput
                                id="email"
                                name="email"
                                label={errors.email && errors.email.length ? errors.email : 'Your email'}
                                value={email}
                                onChange={e => this.changeHandler(e)}
                                icon="envelope"
                                group
                                type="email"
                                disabled={disabled}
                                containerClass="contact-us-container"
                                className="contact-us-input"
                                labelClass="contact-us-label"
                                inputRef={ref => this.emailRef = ref}
                              />

                              <div className="md-form form-group contact-us-container">
                                <h6 className="h6-responsive mt-5 mb-3 custom-file-header">
                                  {errors.file && errors.file.length ? errors.file : 'Your resume'}
                                </h6>
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <MDBIcon icon="file" size="2x" className="mr-3" />
                                  </div>
                                  <div className="custom-file">
                                    <input
                                      id="file"
                                      name="file"
                                      type="file"
                                      onChange={e => { this.changeHandler(e) }}
                                      onClick={e => { this.changeHandler(e) }}
                                      className="custom-file-input"
                                      aria-describedby="inputGroupFileAddon01"
                                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf,text/html,text/plain"
                                      ref={ref => this.fileRef = ref}
                                    />
                                    <label className="custom-file-label d-none d-sm-block" htmlFor="file">
                                      {file && file.length ? file : 'Choose file'}
                                    </label>
                                    <label className="custom-file-label d-block d-sm-none" htmlFor="file">
                                      {file && file.length ? '...' : ''}
                                    </label>
                                  </div>
                                </div>
                                <div className="mt-3 d-flex justify-content-start">
                                  <div className="ml-5"></div>
                                  <small>Allowed file types are: pdf, doc, docx, rtf, html or txt.</small>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center mt-3 mb-3">
                                <div className="d-none d-sm-block">
                                  <ReCAPTCHA
                                    sitekey={siteKey}
                                    grecaptcha={grecaptchaObject}
                                    size="normal"
                                    onChange={this.handleGoogleRecaptchaChange}
                                  />
                                </div>
                                <div className="d-block d-sm-none">
                                  <ReCAPTCHA
                                    sitekey={siteKey}
                                    grecaptcha={grecaptchaObject}
                                    size="compact"
                                    onChange={this.handleGoogleRecaptchaChange}
                                  />
                                </div>
                              </div>
                              {!isGoogleRecaptchaVerified ?
                                <div className="text-center">
                                  <div className="contact-us-container invalid">
                                    <small id="recaptcha" className="contact-us-label">Please verify that you are a human</small>
                                  </div>
                                </div>
                                : ''}

                              <div className="text-center mt-3">
                                {mode === 'showForm' ? (
                                  <MDBBtn color="default" type="submit">
                                    Submit <MDBIcon far icon="star" className="ml-2" />
                                  </MDBBtn>
                                ) : (
                                  <i className="fas fa-5x fa-spinner fa-spin mt-3" style={{ color: '#2bbbad' }}></i>
                                )}
                              </div>

                            </div>
                          </form>
                        </MDBCardBody>
                      </MDBCard>
                    ) : ''}
                    {mode === 'submitted' ?
                      <Message
                        success={submittedSuccess}
                        message={submittedMessage}
                        confirm={`You successfully applied for the position of behavior ${job}.`}
                      />
                    : ''}

                  </MDBCol>
                </MDBRow>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

Job.propTypes = {
  initJob: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  bypassGoogleRecaptcha: PropTypes.bool,
  setJob: PropTypes.func.isRequired,
  setFileUploadData: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  initFlow: PropTypes.func.isRequired,
  setMode: PropTypes.func.isRequired,
  initGoogleRecaptcha: PropTypes.func.isRequired,
  failGoogleRecaptcha: PropTypes.func.isRequired,
  changeGoogleRecaptchaValue: PropTypes.func.isRequired,
  employGoogleRecaptcha: PropTypes.func.isRequired,
  unemployGoogleRecaptcha: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  firstName: state.job.firstName,
  lastName: state.job.lastName,
  email: state.job.email,
  file: state.job.file,
  fileUploadData: state.job.fileUploadData,
  job: state.job.job,
  errors: state.job.errors,
  disabled: state.flow.disabled,
  mode: state.flow.mode,
  submittedSuccess: state.flow.submittedSuccess,
  submittedMessage: state.flow.submittedMessage,
  bypassGoogleRecaptcha: state.recaptcha.bypassGoogleRecaptcha,
  googleRecaptchaValue: state.recaptcha.googleRecaptchaValue,
  isGoogleRecaptchaVerified: state.recaptcha.isGoogleRecaptchaVerified,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
      initJob,
      setJob,
      setFileUploadData,
      setField,
      setError,
      initFlow,
      setMode,
      initGoogleRecaptcha,
      failGoogleRecaptcha,
      changeGoogleRecaptchaValue,
      employGoogleRecaptcha,
      unemployGoogleRecaptcha,
    },
    dispatch
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Job));
