import api from '../Api';

import React from 'react';

const Services = () =>
<div className="ml-3 mr-3">

  <div className="row d-flex justify-content-center">
    <div className="col-10">

      <div className="row">
        <div className="col-10 m-5">
          <h2 className="h2-responsive mt-0 font-weight-bold">Services</h2>

            <p className="lead">Behavior Guidance Group is currently vendored with:</p>
            <div className="list-group-flush ml-2 mr-2">
              {api.services.vendors.map(vendor =>
                <div className="list-group-item" key={`services-vender-${vendor}`}>
                  <div className="mb-0 d-flex justify-content-start">
                    <div><i className="fas fa-check mr-4 default-color p-2 white-text rounded" aria-hidden="true"></i></div>
                    <div className="d-none d-sm-block">{vendor}</div>
                    <small className="d-block d-sm-none">{vendor}</small>
                  </div>
                </div>
              )}
            </div>

            <p className="lead mt-3">Behavior Guidance Group is currently credentialed with:</p>
            <div className="list-group-flush ml-2 mr-2">
              {api.services.credentials.map(cred =>
                <div className="list-group-item" key={`services-credential-${cred}`}>
                  <div className="mb-0 d-flex justify-content-start">
                    <div><i className="fas fa-check mr-4 indigo p-2 white-text rounded" aria-hidden="true"></i></div>
                    <div className="d-none d-sm-block">{cred}</div>
                    <small className="d-block d-sm-none">{cred}</small>
                  </div>
                </div>
              )}
            </div>

            <p className="lead mt-3">Behavior Guidance Group provides services for a wide range of areas including</p>

            <div className="card-group d-flex justify-content-around">

              <div className="card mb-4 mr-3 ml-3">
                <div className="view overlay aqua-gradient white-text text-center">
                  <i className="fas fa-chart-area fa-4x p-2"></i>
                  <a href="#!">
                    <div className="mask rgba-white-slight"></div>
                  </a>
                </div>

                <div className="card-body">
                  <h4 className="card-title h4-responsive">The reduction of:</h4>
                  <div className="list-group-flush">
                    {api.services.reducing.map(item =>
                      <div key={`services-decreasing-${item}`}>
                        {/* for view ports that are medium and larger */}
                        <div className="list-group-item d-none d-md-block">
                          <div className="mb-0 d-flex justify-content-start">
                            <div><i className="fas fa-check mr-4 aqua-gradient p-1 white-text rounded" aria-hidden="true"></i></div>
                            <div>{item}</div>
                          </div>
                        </div>
                        {/* for view ports that are smaller than medium */}
                        <div className="list-group-item md d-block d-md-none">
                          <div className="mb-0 d-flex justify-content-start">
                            <div><i className="fas fa-check mr-2 aqua-gradient p-1 white-text rounded" aria-hidden="true"></i></div>
                            <div className="small">{item}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card mb-4 ml-3 mr-3">
                <div className="view overlay purple-gradient white-text text-center">
                  <i className="fas fa-chart-line fa-4x p-2"></i>
                  <a href="#!">
                    <div className="mask rgba-white-slight"></div>
                  </a>
                </div>

                <div className="card-body">
                  <h4 className="card-title h4-responsive">And Increasing:</h4>
                  <div className="list-group-flush">
                    {api.services.increasing.map(item =>
                      <div key={`services-increasing-${item}`}>
                        {/* for view ports that are medium and larger */}
                        <div className="list-group-item d-none d-md-block">
                          <div className="mb-0 d-flex justify-content-start">
                            <div><i className="fas fa-check mr-4 purple-gradient p-1 white-text rounded" aria-hidden="true"></i></div>
                            <div>{item}</div>
                          </div>
                        </div>
                        {/* for view ports that are smaller than medium */}
                        <div className="list-group-item md d-block d-md-none">
                          <div className="mb-0 d-flex justify-content-start">
                            <div><i className="fas fa-check mr-2 purple-gradient p-1 white-text rounded" aria-hidden="true"></i></div>
                            <div className="small">{item}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
               </div>
              </div>

            </div>

            <p className="lead mt-3">We specialize in the most effective parent and caregiver training to allow optimal results.</p>
            <p className="lead mt-3">In addition to Board Certified Behavior Analysts, Behavior Guidance Group's team includes both a licensed psychologist and a licensed social worker to provide a  collaborative approach for each client's intervention plan.</p>
            <p className="lead mt-3">Behavior Guidance Group also consults with schools, administering teacher and staff training. In addition, we work as advisors, conducting Functional Behavior Assessments (FBA) for students and creating Behavior Intervention and Support Plans (BSP/BIP). We then collaborate with other service providers during IEPs and guide implementation of the BSPs in the classroom.</p>
            <p className="lead mt-3">We offer BCBA supervision hours, in compliance with the most recent BACB standards, for those preparing to sit for the BCBA or BCaBA exam.</p>

        </div>
      </div>

    </div>
  </div>

</div>

export default Services;
