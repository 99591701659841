import React from 'react';
import {Link} from 'react-router-dom';

const Home = () =>
<div className="ml-3 mr-3">

  <div className="row d-flex justify-content-center">
    <div className="col-10">

        <div className="row text-center mt-5">
          <div className="col-md-6 mb-5">
            <img src="img/behavior-guidance-group.png" className="img-fluid" alt="Behavior Guidance Group" />
          </div>
          <div className="col-md-6 mb-5">
            <h2 className="display-4">Behavior Guidance Group</h2>

            <p className="lead">Guiding you & your loved ones every step of the way.</p>
            <hr className="my-4" />

            <Link to="/contact-us" className="btn btn-default btn-lg waves-effect" role="button">
              Contact Us
              <i className="fas fa-mobile-alt ml-2"></i>
            </Link>
          </div>
        </div>

    </div>
  </div>

  <div className="row d-flex justify-content-center mt-5 mb-5">
    <div className="col-10">

      <div className="card-deck">

        <div className="card mb-4">
          <div className="view overlay">
            <img className="card-img-top" src="img/about-us.jpg" alt="About Us" />
            <a href="about-us/index.html">
              <div className="mask rgba-white-slight"></div>
            </a>
          </div>

          <div className="card-body">
            <h4 className="card-title">
              <i className="fas fa-1x fa-user-circle mr-1"></i>
              About Us
            </h4>
            <p className="card-text">Behavior Guidance Group's co-clinical directors have 20 years of field experience each and are PhD level Board Certified Behavior Analysts. Our professional staff is sensitive to the unique needs of our clients. Our goal of service is to identify the variables that influence behavior and then make the requisite changes to establish new repertoires and teach relevant skills.</p>
            <Link to="/about-us" className="btn btn-default btn-md waves-effect" role="button">
              Read more
              <i className="fas fa-angle-double-right ml-1"></i>
            </Link>
          </div>
        </div>

        <div className="card mb-4">
          <div className="view overlay">
            <img className="card-img-top" src="img/services.jpg" alt="Services" />
            <a href="#!">
              <div className="mask rgba-white-slight"></div>
            </a>
          </div>

          <div className="card-body">
            <h4 className="card-title">
              <i className="fas fa-1x fa-hand-holding-heart mr-1"></i>
              Services
            </h4>
            <p className="card-text">Behavior Guidance Group provides services for a wide range of areas focusing on the reduction of maladaptive behaviors and increasing socially significant skills. All programs are tailored to meet the unique needs of each individual. Most importantly, we specialize in effective parent and caregiver training to allow optimal results.</p>
            <Link to="/services" className="btn btn-default btn-md waves-effect" role="button">
              Read more
              <i className="fas fa-angle-double-right ml-1"></i>
            </Link>
          </div>

        </div>

        <div className="card mb-4">
          <div className="view overlay">
            <img className="card-img-top" src="img/philosophy.jpg" alt="Philosophy" />
            <a href="#!">
              <div className="mask rgba-white-slight"></div>
            </a>
          </div>

          <div className="card-body">
            <h4 className="card-title">
              <i className="fas fa-1x fa-book-open mr-1"></i>
              Philosophy
            </h4>
            <p className="card-text">At Behavior Guidance Group we are committed to provide effective, empirically validated educational and behavioral services to individuals with autism and other developmental disabilities. Our nurturing staff is dedicated to not only delivering custom programs for the individual, but also improving the quality of life of the entire family. Additionally, our emphasis on parent training enables our clients' parents and caretakers to take a proactive role in their children's ongoing progress.</p>
            <Link to="/philosophy" className="btn btn-default btn-md waves-effect" role="button">
              Read more
              <i className="fas fa-angle-double-right ml-1"></i>
            </Link>
          </div>

        </div>

      </div>

    </div>
  </div>

</div>

export default Home;
