// ACTIONS: defined in root level reducers

// INITIAL STATE
const initState = () => ({
  bypassGoogleRecaptcha: false,
  googleRecaptchaValue: null,
  isGoogleRecaptchaVerified: true,
});

// REDUCERS
const recaptchaReducer = (state = initState(), action) => {
  switch (action.type.split('/').pop()) {
    case 'INIT_GOOGLE_RECAPTCHA': {
      return initState();
    }
    case 'FAIL_GOOGLE_RECAPTCHA': {
      return {
        ...state,
        isGoogleRecaptchaVerified: false,
      };
    }
    case 'CHANGE_GOOGLE_RECAPTCHA_VALUE': {
      return {
        ...state,
        googleRecaptchaValue: action.value,
        isGoogleRecaptchaVerified: action.value ? true : false,
      };
    }
    case 'EMPLOY_GOOGLE_RECAPTCHA': {
      return {
        ...state,
        bypassGoogleRecaptcha: false,
      };
    }
    case 'UNEMPLOY_GOOGLE_RECAPTCHA': {
      return {
        ...state,
        bypassGoogleRecaptcha: true,
      };
    }
    default:
      return state;
  }
};

export default recaptchaReducer;

// ACTION CREATORS: defined in root level reducers

// THUNKS: defined in root level reducers
