// ACTIONS: defined in root level reducers

// INITIAL STATE
const initState = () => ({
  disabled: false,
  mode: 'showForm', // 'showForm', 'submitting', 'submitted'
  submittedSuccess: false,
  submittedMessage: '',
});

// REDUCERS
const flow = (state = initState(), action) => {
  switch (action.type.split('/').pop()) {
    case 'INIT_FLOW': {
      return initState();
    }
    case 'SET_MODE': {
      if (action.mode === 'submitting') {
        return {
          ...state,
          disabled: true,
          mode: action.mode,
        };
      }
      else if (action.mode === 'submitted') {
        return {
          ...state,
          disabled: false,
          mode: action.mode,
          submittedSuccess: action.success,
          submittedMessage: action.message,
        };
      }
      else if (action.mode === 'showForm') {
        return initState();
      }
      break;
    }

    default:
      return state;
  }
};

export default flow;

// ACTION CREATORS: defined in root level reducers

// THUNKS: defined in root level reducers
