import React from 'react';

const content = 'Behavior analysis is the science of behavior. Our goal of service is twofold. First, we are committed to reducing maladaptive behaviors. Second, we strive to make meaningful changes in the lives of our clients and their families, providing them with the necessary tools to allow autonomy and an ultimate fade out of services. Although our program is grounded in well-established principles of learning and evidence based treatments, we tailor every plan to meet each individual client’s need.';

const Philosophy = () =>
<div className="ml-3 mr-3">
  <div className="row d-flex justify-content-center">
    <div className="col-10">

      <div className="row d-none d-sm-block vh-100">
        <div className="col-10 m-5">
          <h2 className="h2-responsive mt-0 font-weight-bold">Philosophy</h2>
          <p className="lead">{content}</p>
        </div>
      </div>

      <div className="row d-block d-sm-none">
        <div className="col-10 m-5">
          <h2 className="h2-responsive mt-0 font-weight-bold">Philosophy</h2>
          <p className="lead">{content}</p>
        </div>
      </div>

    </div>
  </div>
</div>

export default Philosophy;
